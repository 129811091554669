import React from "react"

import { Helmet } from "react-helmet"
import Header from "../components/headers/header"
import Footer from "../components/footers/footer"

import SectionIntro from "../components/index/SectionIntro"
import SectionPromo from "../components/index/SectionPromo"
import SectionConseil  from "../components/index/SectionConseil"
import SectionFaq  from "../components/index/SectionFaq"
import SectionAssu from "../components/index/SectionAssu"
import SectionProduct from "../components/index/SectionProduct"

export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Fissurometre.fr</title>
        <meta name="description" content="Fissurometre.fr, un service innovant et unique en France, pour vous aider à résoudre votre problématique de fissuration." />
        <link rel="canonical" href="https://www.fissurometre.fr/" />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <SectionIntro />
        <SectionPromo />
        <SectionProduct />
        <SectionAssu />
        <SectionConseil />
        <SectionFaq />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}