import React from "react"
import { StaticImage } from "gatsby-plugin-image"


const products = [
    {
      id: 1,
      name: 'Leather Long Wallet',
      color: 'Natural',
      price: '$75',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-04-trending-product-02.jpg',
      imageAlt: 'Hand stitched, orange leather long wallet.',
    },
    {
        id: 2,
        name: 'Leather Long Wallet',
        color: 'Natural',
        price: '$75',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-04-trending-product-02.jpg',
        imageAlt: 'Hand stitched, orange leather long wallet.',
    },
    {
        id: 3,
        name: 'Leather Long Wallet',
        color: 'Natural',
        price: '$75',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-04-trending-product-02.jpg',
        imageAlt: 'Hand stitched, orange leather long wallet.',
    },
    {
        id: 4,
        name: 'Leather Long Wallet',
        color: 'Natural',
        price: '$75',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-04-trending-product-02.jpg',
        imageAlt: 'Hand stitched, orange leather long wallet.',
    },
    // More products...
  ]
  
  export default function Example() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <div className="md:flex md:items-center md:justify-between">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900">Top vente fissuromètres et témoins de fissures</h2>
            {/* <a href="#" className="hidden text-sm font-medium text-sky-600 hover:text-sky-500 md:block">
              Voir les fissuromètres
              <span aria-hidden="true"> &rarr;</span>
            </a> */}
          </div>
  
          <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
              <div className="group relative">
                <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                  <StaticImage
                    src="../../images/1_unite.jpg"
                    alt="Jauge Saugnac G1"
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">
                  <a href="https://buy.stripe.com/4gw4jy3TQ9s49oc00m" target="_blank">
                    <span className="absolute inset-0" />
                    Jauge Saugnac G1
                  </a>
                </h3>
                <p className="mt-1 text-sm text-gray-500">1 unité</p>
                <p className="mt-1 text-sm font-medium text-gray-900">29,90€ TTC</p>
              </div>
              <div className="group relative">
                <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                  <StaticImage
                    src="../../images/2_unites.jpg"
                    alt="Jauge Saugnac G1"
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">
                  <a href="https://buy.stripe.com/cN22bq0HE9s4dEs6ov" target="_blank">
                    <span className="absolute inset-0" />
                    Jauge Saugnac G1
                  </a>
                </h3>
                <p className="mt-1 text-sm text-gray-500">2 unités</p>
                <p className="mt-1 text-sm font-medium text-gray-900">59,20€ TTC</p>
              </div>
              <div className="group relative">
                <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                  <StaticImage
                    src="../../images/3_unites.jpg"
                    alt="Jauge Saugnac G1"
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">
                  <a href="https://buy.stripe.com/bIY17mgGCcEgeIw9AE" target="_blank">
                    <span className="absolute inset-0" />
                    Jauge Saugnac G1
                  </a>
                </h3>
                <p className="mt-1 text-sm text-gray-500">3 unités</p>
                <p className="mt-1 text-sm font-medium text-gray-900">81,80€ TTC</p>
              </div>
              <div className="group relative">
                <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                  <StaticImage
                    src="../../images/4_unites.jpg"
                    alt="Jauge Saugnac G1"
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">
                  <a href="https://buy.stripe.com/4gw17mfCyeMoasgfZ7" target="_blank">
                    <span className="absolute inset-0" />
                    Jauge Saugnac G1
                  </a>
                </h3>
                <p className="mt-1 text-sm text-gray-500">4 unités</p>
                <p className="mt-1 text-sm font-medium text-gray-900">111,30€ TTC</p>
              </div>
          </div>
  
          <div className="mt-8 text-sm md:hidden">
            <a href="#" className="font-medium text-sky-600 hover:text-sky-500">
              Shop the collection
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </div>
      </div>
    )
  }
  