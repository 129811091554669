import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export default function Example() {
    return (
      <div className="relative bg-gray-800 px-6 py-32 sm:px-12 sm:py-40 lg:px-16">
        <div className="absolute inset-0 overflow-hidden">
            <StaticImage
              src="../../images/mur-fissure.jpg"
              alt="Jauge Saugnac"
              className="h-full w-full object-cover object-center"
            />
        </div>
        <div aria-hidden="true" className="absolute inset-0 bg-gray-900 bg-opacity-50" />
        <div className="relative mx-auto flex max-w-4xl flex-col items-center text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Nos conseils pour analyser les fissures et les surveiller</h2>
          <p className="mt-3 text-xl text-white">
          Les fissures ne sont pas toujours simples à comprendre. Sécheresse, malfaçons, problème de construction…  A quoi sont-elles dues ? Sont-elles graves ? Vont-elles s’aggraver ? Pour analyser les fissures, les surveiller dans le temps et vous aider à prendre les bonnes décisions, nous vous mettons, si besoin, en rapport à un expert fissures indépendant des assurances.
          </p>
          <a
            href="/contact/"
            className="mt-8 block w-full rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto"
          >
            Expliquez-nous votre problématique
          </a>
        </div>
      </div>
    )
  }
  