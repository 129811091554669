import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    theme: {
      extend: {
        gridTemplateRows: {
          '[auto,auto,1fr]': 'auto auto 1fr',
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
//import { useState } from 'react'
//import { StarIcon } from '@heroicons/react/24/solid'
//import { RadioGroup } from '@headlessui/react'

// const product = {
//   name: 'Basic Tee 6-Pack',
//   price: '$192',
//   href: '#',
//   breadcrumbs: [
//     { id: 1, name: 'Men', href: '#' },
//     { id: 2, name: 'Clothing', href: '#' },
//   ],
//   images: [
//     {
//       src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-secondary-product-shot.jpg',
//       alt: 'Two each of gray, white, and black shirts laying flat.',
//     },
//     {
//       src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-01.jpg',
//       alt: 'Model wearing plain black basic tee.',
//     },
//     {
//       src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-02.jpg',
//       alt: 'Model wearing plain gray basic tee.',
//     },
//     {
//       src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-featured-product-shot.jpg',
//       alt: 'Model wearing plain white basic tee.',
//     },
//   ],
//   colors: [
//     { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
//     { name: 'Gray', class: 'bg-gray-200', selectedClass: 'ring-gray-400' },
//     { name: 'Black', class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
//   ],
//   sizes: [
//     { name: 'XXS', inStock: false },
//     { name: 'XS', inStock: true },
//     { name: 'S', inStock: true },
//     { name: 'M', inStock: true },
//     { name: 'L', inStock: true },
//     { name: 'XL', inStock: true },
//     { name: '2XL', inStock: true },
//     { name: '3XL', inStock: true },
//   ],
//   description:
//     'The Basic Tee 6-Pack allows you to fully express your vibrant personality with three grayscale options. Feeling adventurous? Put on a heather gray tee. Want to be a trendsetter? Try our exclusive colorway: "Black". Need to add an extra pop of color to your outfit? Our white tee has you covered.',
//   highlights: [
//     'Hand cut and sewn locally',
//     'Dyed with our proprietary colors',
//     'Pre-washed & pre-shrunk',
//     'Ultra-soft 100% cotton',
//   ],
//   details:
//     'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
// }
// const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  // const [selectedColor, setSelectedColor] = useState(product.colors[0])
  // const [selectedSize, setSelectedSize] = useState(product.sizes[2])

  return (
    <div className="bg-white">
      <div className="pt-6">
        {/* <nav aria-label="Breadcrumb">
          <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            {product.breadcrumbs.map((breadcrumb) => (
              <li key={breadcrumb.id}>
                <div className="flex items-center">
                  <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                    {breadcrumb.name}
                  </a>
                  <svg
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    aria-hidden="true"
                    className="h-5 w-4 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                {product.name}
              </a>
            </li>
          </ol>
        </nav> */}

        {/* Image gallery */}
        <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
          <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
            <div className="h-full w-full object-cover object-center">
              <StaticImage
                src="../../images/jauge-01.jpg"
                alt="Jauge Saugnac"
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
            {/* <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg"> */}
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
              <div className="h-full w-full object-cover object-center">
                <StaticImage
                  src="../../images/jauge-02.jpg"
                  alt="Jauge Saugnac"
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </div>
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
              <div className="h-full w-full object-cover object-center">
                <StaticImage
                  src="../../images/jauge-03.jpg"
                  alt="Jauge Saugnac"
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </div>
          </div>
          <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
            <div className="h-full w-full object-cover object-center">
              <StaticImage
                src="../../images/Fissuromere-Jauge-Saugnac-G1-Boutique-Koudepouce.jpg"
                alt="Jauge Saugnac"
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
        </div>



        {/* Product info */}
        <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Fissuromètre Jauge Saugnac G1</h1>
            <h2 className="text-1xl font-bold tracking-tight text-gray-900 sm:text-2xl">Mesure simple et précise des fissures et de leur évolution dans le temps</h2>
          </div>

          {/* Options */}
          <div className="mt-4 lg:row-span-3 lg:mt-0">
            <h2 className="sr-only">Product information</h2>
            <p className="text-3xl tracking-tight text-gray-900">A partir de 29,90€</p>
            <p className="text-1xl tracking-tight text-gray-900">Prix TTC et frais de port inclus en France métropolitaine</p>

            {/* <div className="mt-6">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        reviews.average > rating ? 'text-gray-900' : 'text-gray-200',
                        'h-5 w-5 flex-shrink-0'
                      )}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="sr-only">{reviews.average} out of 5 stars</p>
                <a href={reviews.href} className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                  {reviews.totalCount} reviews
                </a>
              </div>
            </div> */}

            {/* <form className="mt-10">
              
              <div>
                <h3 className="text-sm font-medium text-gray-900">Color</h3>

                <RadioGroup value={selectedColor} onChange={setSelectedColor} className="mt-4">
                  <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                  <div className="flex items-center space-x-3">
                    {product.colors.map((color) => (
                      <RadioGroup.Option
                        key={color.name}
                        value={color}
                        className={({ active, checked }) =>
                          classNames(
                            color.selectedClass,
                            active && checked ? 'ring ring-offset-1' : '',
                            !active && checked ? 'ring-2' : '',
                            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none'
                          )
                        }
                      >
                        <RadioGroup.Label as="span" className="sr-only">
                          {color.name}
                        </RadioGroup.Label>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            color.class,
                            'h-8 w-8 rounded-full border border-black border-opacity-10'
                          )}
                        />
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>

              
              <div className="mt-10">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">Size</h3>
                  <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                    Size guide
                  </a>
                </div>

                <RadioGroup value={selectedSize} onChange={setSelectedSize} className="mt-4">
                  <RadioGroup.Label className="sr-only">Choose a size</RadioGroup.Label>
                  <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                    {product.sizes.map((size) => (
                      <RadioGroup.Option
                        key={size.name}
                        value={size}
                        disabled={!size.inStock}
                        className={({ active }) =>
                          classNames(
                            size.inStock
                              ? 'cursor-pointer bg-white text-gray-900 shadow-sm'
                              : 'cursor-not-allowed bg-gray-50 text-gray-200',
                            active ? 'ring-2 ring-indigo-500' : '',
                            'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6'
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label as="span">{size.name}</RadioGroup.Label>
                            {size.inStock ? (
                              <span
                                className={classNames(
                                  active ? 'border' : 'border-2',
                                  checked ? 'border-indigo-500' : 'border-transparent',
                                  'pointer-events-none absolute -inset-px rounded-md'
                                )}
                                aria-hidden="true"
                              />
                            ) : (
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                              >
                                <svg
                                  className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                  viewBox="0 0 100 100"
                                  preserveAspectRatio="none"
                                  stroke="currentColor"
                                >
                                  <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />
                                </svg>
                              </span>
                            )}
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>

              <button
                type="submit"
                className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Acheter 1 unité (84 € HT)
              </button>
            </form> */}
            <a href="https://buy.stripe.com/4gw4jy3TQ9s49oc00m" target="_blank">
              <button
                className="ring-sky-500 ring-1 mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-200 px-8 py-3 text-base font-medium text-gray-800 hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
              >
                1 Jauge Saugnac G1 à l'unité - 29,90€
              </button>
            </a>
            <a href="https://buy.stripe.com/cN22bq0HE9s4dEs6ov" target="_blank">
              <button
                className="ring-sky-500 ring-1 mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-sky-500 px-8 py-3 text-base font-medium text-gray-800 hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
              >
                Pack de 2 Jauges Saugnac G1 - 59,20€
              </button>
            </a>
            <a href="https://buy.stripe.com/bIY17mgGCcEgeIw9AE" target="_blank">
              <button
                className="ring-sky-500 ring-1 mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-200 px-8 py-3 text-base font-medium text-gray-800 hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
              >
                Pack de 3 Jauges Saugnac G1 - 81,80€
              </button>
            </a>
            <a href="https://buy.stripe.com/4gw17mfCyeMoasgfZ7" target="_blank">
              <button
                className="ring-sky-500 ring-1 mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-200 px-8 py-3 text-base font-medium text-gray-800 hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
              >
                Pack de 4 Jauges Saugnac G1 - 111,60€
              </button>
            </a>
            <a href="https://buy.stripe.com/8wMdU8fCy5bOgQEeV2" target="_blank">
              <button
                className="ring-sky-500 ring-1 mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-200 px-8 py-3 text-base font-medium text-gray-800 hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
              >
                Pack de 5 Jauges Saugnac G1 - 139,70€
              </button>
            </a>
            <p className="text-xs mt-8">
              En réglant votre commande, vous acceptez les <a className="underline text-sky-500" href="/pages-juridiques/cgv/" target="_blank">Conditions Générales de Vente</a>
            </p>

            <StaticImage
              src="../../images/stripe.png"
              alt="Jauge Saugnac"
              className="w-full object-cover object-center mt-8"
            />
          </div>

          <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
            {/* Description and details */}
            <div>

              <div className="space-y-6">
                <p className="text-base text-gray-900">
                  En cas de sinistre sur votre <strong>maison</strong> ou <strong>immeuble</strong>, la première chose à faire est d’identifier les <strong>causes</strong> des <strong>fissures structurelles</strong> pour mieux les éliminer.
                </p>
                <p className="text-base text-gray-900">
                  Le processus de <strong>réparation</strong> d’un <strong>bâtiment fissuré</strong> est souvent <strong>long</strong> et <strong>coûteux</strong>. Il est ainsi fréquent que les <strong>fissures se développent au fil du temps</strong>.
                </p>
                <p className="text-base text-gray-900">
                  Pour prendre les bonnes décisions (travaux immédiats et de moyen terme) , assurez-vous de <strong>surveiller la progression des fissures</strong> en utilisant des équipements de mesure (fissuromètre, jauge, témoin…) renommés pour leur <strong>fiabilité</strong> et leur <strong>exactitude</strong>, tels qu'une Jauge Saugnac G1.

                </p>
              </div>
            </div>

            <div className="mt-10">
              <h3 className="text-sm font-medium text-gray-900">Caractéristiques</h3>

              <div className="mt-4">
                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                  <li className="text-gray-400">
                    <span className="text-gray-600">Fissuromètre de référence en France</span>
                  </li>
                  <li className="text-gray-400">
                    <span className="text-gray-600">Dimensions 140 X 40 mm</span>
                  </li>
                  <li className="text-gray-400">
                    <span className="text-gray-600">Épaisseur totale 3 mm</span>
                  </li>
                  <li className="text-gray-400">
                    <span className="text-gray-600">Poids 4g</span>
                  </li>
                  <li className="text-gray-400">
                    <span className="text-gray-600">Force de traction proche de 25g</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="mt-10">
              <h3 className="text-sm font-medium text-gray-900">Avantages</h3>

              <div className="mt-4 space-y-6">
                <p className="text-sm text-gray-600">Sur notre site, nous distribuons des pack d’1 à 5 unités des Jauges Saugnac G1
                  Nous vous répondons à vos questions sur la pose et vous pouvez obtenir des conseils ad hoc auprès d’un expert fissures si nécessaire.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
