/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React from "react"
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { StaticImage } from "gatsby-plugin-image"


const categories = [
  {
    name: 'New Arrivals',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg',
  },
  {
    name: 'Productivity',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-02.jpg',
  },
  {
    name: 'Workspace',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-04.jpg',
  },
  {
    name: 'Accessories',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-05.jpg',
  },
  { 
    name: 'Sale', 
    href: '#', 
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-03.jpg' 
  },
  
]

const faqs = [
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
]

export default function Example() {
  return (
    <div className="bg-sky-500">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-24">
        <div className="mx-auto max-w-7xl ">
          <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0 lg:py-8">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Questions fréquentes sur l’usage d’un fissuromètre</h2>
            {/* <a href="/faq/" className="hidden text-sm font-semibold text-amber-500 hover:text-amber-400 sm:block">
              Voir toutes les questions
              <span aria-hidden="true"> &rarr;</span>
            </a> */}
          </div>

          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Comment mesurer une fissure ?</dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">Pour mesurer une fissure, vous pouvez utiliser une règle graduée ou encore un pied à coulisse. Les fissures font généralement quelques millimètres. Il est donc important que votre outil de mesure soit d’une précision de l’ordre du dixième de millimètre. </p>
              </dd>
            </div>
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Comment surveiller une fissure ?</dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">Dans 9 cas sur 10 les fissures sont structurelles. Elles s’expliquent régulièrement par une problématique de sol (mouvement de terrain, argile gonflante, sécheresse…). Avec le temps, elles peuvent s’aggraver. La surveillance des fissures est un réflexe à avoir. La méthode la plus efficace et fiable pour mesurer l’évolution d’une fissure est d’utiliser un fissuromètre, un témoin de fissures ou encore une jauge pour fissure. 
</p>
              </dd>
            </div>
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Quelle est la définition d'un fissuromètre ?</dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">Un fissuromètre est un outil permettant d’identifier de possibles évolutions des fissures. Il permet de constater sa stabilisation ou au contraire un mouvement (ouverture ou fermeture de la fissures). Il existe différents types de fissuromètres (manuels ou numériques). Il s’agit d’un outil de mesure graduée pour l’analyse de l'augmentation ou la réduction de la distance d'écartement au fil du temps.</p>
              </dd>
            </div>
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Quel fissuromètre poser sur la façade d’une maison ou un immeuble ?</dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">Le modèle le plus répandu est la Jauge Saugnac G1. C’est un fissuromètre qui peut être appliqué en extérieur (résistant aux intempéries) ou en intérieur. Il est utilisé par les experts d’assurance et experts bâtiment privés. Son usage étant répandu, les mesures obtenues sont généralement moins contestées par rapport à un fissuromètre asiatique, acheté sur Amazon et offrant supposément moins de fiabilité.</p>
              </dd>
            </div>
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Comment poser un fissuromètre ?</dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">Un fissuromètre peut être posé avec de la colle sur une surface lisse et non soumise aux intempéries. En extérieur, il est conseillé d’utiliser des chevilles et vis. Pour vous guider dans la pose du fissuromètre, nous vous guidons après commande (notice de pose, vidéos explicatives…). La pose d’un fissuromètre est à la portée de tous (bricoleurs ou non). Mais si vous rencontrez des problèmes, nous pouvons aussi vous aider (poseurs agréés).</p>
              </dd>
            </div>
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Comment utiliser un fissuromètre ?</dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">Avant la pose du fissuromètre, nous vous recommandons de mesurer l’ouverture de la fissure. Puis une fois posé, il est d’usage de procéder à un relevé régulier des valeurs mesurées. Une fois par mois ou tous les 15 jours par exemple. Il est conseillé de prendre une photo du fissuromètre et de reporter les valeurs mesurées dans un document (tableur par exemple). En cas d’évolution rapide des fissures (aggravation), notre équipe d’experts bâtiment est à vos côtés pour vous conseiller.</p>
              </dd>
            </div>
        </dl>


          {/* <dl className="mt-0 space-y-6 ">
              <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-7xl">
                <div class="md:flex">
                  <div class="md:shrink-0">
                    <StaticImage
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src="../../images/Jauge-G1-fissure-3.jpg" 
                      alt="Fissuration"
                    />
                  </div>
                  <div class="p-8 w-full">
                    <Disclosure as="div" className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base font-semibold leading-7">
                              Comment mesurer une fissure ?
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">
                            I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-7xl">
                <div class="md:flex">
                  <div class="md:shrink-0">
                    <StaticImage
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src="../../images/Jauge-G1-fissure-3.jpg" 
                      alt="Fissuration"
                    />
                  </div>
                  <div class="p-8 w-full">
                    <Disclosure as="div" className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base font-semibold leading-7">
                              Comment surveiller une fissure ?
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">
                            I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-7xl">
                <div class="md:flex">
                  <div class="md:shrink-0">
                    <StaticImage
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src="../../images/Jauge-G1-fissure-3.jpg" 
                      alt="Fissuration"
                    />
                  </div>
                  <div class="p-8 w-full">
                    <Disclosure as="div" className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base font-semibold leading-7">
                              Quelle est la définition d'un fissuromètre ?
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">
                            I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-7xl">
                <div class="md:flex">
                  <div class="md:shrink-0">
                    <StaticImage
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src="../../images/Jauge-G1-fissure-3.jpg" 
                      alt="Fissuration"
                    />
                  </div>
                  <div class="p-8 w-full">
                    <Disclosure as="div" className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base font-semibold leading-7">
                              Comment poser un fissuromètre ?
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">
                            I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-7xl">
                <div class="md:flex">
                  <div class="md:shrink-0">
                    <StaticImage
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src="../../images/Jauge-G1-fissure-3.jpg" 
                      alt="Fissuration"
                    />
                  </div>
                  <div class="p-8 w-full">
                    <Disclosure as="div" className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base font-semibold leading-7">
                              Comment utiliser un fissuromètre ?
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">
                            I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
          </dl> */}
        </div>
      </div>
    </div>
  )
}

  