import React from "react";
import {
  TruckIcon,
  UserIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";

const perks = [
  {
    name: "Des conseils d'expert fissures",
    description: "Fissurometre.fr",
    icon: UserIcon,
  },
  {
    name: "Les meilleurs produits",
    description: "Satisfaction assurée",
    icon: HandThumbUpIcon,
  },
  {
    name: "Livraison rapide chez vous",
    description: "24/48h",
    icon: TruckIcon,
  },
];

export default function Product() {
  return (
    <div className="bg-gray-100">
      <div className="px-8 mx-auto max-w-7xl divide-y divide-gray-200 lg:flex lg:justify-left lg:divide-x lg:divide-y-0 lg:py-8">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900">
          Les meilleurs solutions pour protéger votre maison
        </h2>
      </div>
      <div className="mx-auto max-w-7xl divide-y divide-gray-200 lg:flex lg:justify-center lg:divide-x lg:divide-y-0 lg:py-8">
        {perks.map((perk, perkIdx) => (
          <div key={perkIdx} className="py-8 lg:w-1/3 lg:flex-none lg:py-0">
            <div className="mx-auto flex max-w-xs items-center px-4 lg:max-w-none lg:px-8">
              <perk.icon
                className="h-8 w-8 flex-shrink-0 text-sky-600"
                aria-hidden="true"
              />
              <div className="ml-4 flex flex-auto flex-col-reverse">
                <h3 className="font-medium text-gray-900">{perk.name}</h3>
                <p className="text-sm text-gray-500">{perk.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="px-8 mx-auto max-w-7xl divide-y divide-gray-200 lg:flex lg:justify-left lg:divide-x lg:divide-y-0 lg:py-8">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900">
          Comment poser un fissuromètre et suivre l'évolution des désordres sur
          votre maison ?
        </h2>
      </div>
      <div className="mx-auto max-w-7xl divide-y divide-gray-200 lg:flex lg:justify-center lg:divide-x lg:divide-y-0 lg:py-8">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/fNSadfEXFtc?si=wvD6aRzgLqNJol3e"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}
