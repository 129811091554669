
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const categories = [
  {
    name: 'New Arrivals',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg',
  },
  {
    name: 'Productivity',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-02.jpg',
  },
  {
    name: 'Workspace',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-04.jpg',
  },
  {
    name: 'Accessories',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-05.jpg',
  },
  { 
    name: 'Sale', 
    href: '#', 
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-03.jpg' 
  },
  
]

export default function Example() {
  return (
    <div className="bg-white">
      <div className="py-16 sm:py-24 xl:mx-auto xl:max-w-7xl xl:px-8">
        <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0 lg:py-8">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">
              Conseils en cas de fissure sur votre maison
          </h2>
          <a href="/fissure-maison/" className="hidden text-sm font-semibold text-sky-600 hover:text-sky-500 sm:block">
            Voir tous les conseils
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>

        <div className="mt-4 flow-root">
          <div className="-my-2">
            <div className="relative box-content h-80 overflow-x-auto py-2 xl:overflow-visible">
              <div className="absolute flex space-x-8 px-4 sm:px-6 lg:px-8 xl:relative xl:grid xl:grid-cols-5 xl:gap-x-8 xl:space-x-0 xl:px-0">
                  <a
                    href="/fissure-maison/analyse-fissuration/"
                    className="relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto"
                  >
                    <span aria-hidden="true" className="absolute inset-0">
                      <StaticImage
                        src="../../images/analyse-fissure.jpg"
                        alt="Analyse Fissure"
                        className="h-full w-full object-cover object-center"
                      />
                    </span>
                    <span
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                    />
                    <span className="relative mt-auto text-center text-xl font-bold text-white">Analyse Fissuration</span>
                  </a>

                  <a
                    href="/fissure-maison/evaluer-risques/"
                    className="relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto"
                  >
                    <span aria-hidden="true" className="absolute inset-0">
                      <StaticImage
                        src="../../images/evaluer-risque.jpg"
                        alt="Évaluer les risques"
                        className="h-full w-full object-cover object-center"
                      />
                    </span>
                    <span
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                    />
                    <span className="relative mt-auto text-center text-xl font-bold text-white">Évaluer les risques</span>
                  </a>

                  <a
                    href="/fissure-maison/comprendre-causes-fissures/ "
                    className="relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto"
                  >
                    <span aria-hidden="true" className="absolute inset-0">
                      <StaticImage
                        src="../../images/comprendre-cause-fissure.jpg"
                        alt="Comprendre les causes des fissures"
                        className="h-full w-full object-cover object-center"
                      />
                    </span>
                    <span
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                    />
                    <span className="relative mt-auto text-center text-xl font-bold text-white">Comprendre les causes des fissures</span>
                  </a>

                  <a
                    href="/fissure-maison/mesures-conservatoires-urgence/"
                    className="relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto"
                  >
                    <span aria-hidden="true" className="absolute inset-0">
                      <StaticImage
                        src="../../images/mesure-conservatoire.jpg"
                        alt="Mesures conservatoires en urgence"
                        className="h-full w-full object-cover object-center"
                      />
                    </span>
                    <span
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                    />
                    <span className="relative mt-auto text-center text-xl font-bold text-white">Mesures conservatoires en urgence</span>
                  </a>

                  <a
                    href="/fissure-maison/travaux-confortement/"
                    className="relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto"
                  >
                    <span aria-hidden="true" className="absolute inset-0">
                      <StaticImage
                        src="../../images/travaux-confortement.jpg"
                        alt="Travaux de confortement"
                        className="h-full w-full object-cover object-center"
                      />
                    </span>
                    <span
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                    />
                    <span className="relative mt-auto text-center text-xl font-bold text-white">Travaux de confortement</span>
                  </a>

              </div>
            </div>
          </div>
        </div>

        {/* <div className="mt-6 px-4 sm:hidden">
          <a href="#" className="block text-sm font-semibold text-sky-600 hover:text-sky-500">
            Browse all categories
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div> */}
      </div>
    </div>
  )
}

  